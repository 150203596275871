import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [7,[2]],
		"/admin": [28,[6]],
		"/admin/battery": [29,[6]],
		"/admin/battery/create": [31,[6]],
		"/admin/battery/[id]": [30,[6]],
		"/admin/cfg-sig": [32,[6]],
		"/admin/country-limits": [33,[6]],
		"/admin/country-limits/create": [34,[6]],
		"/admin/device-compatibility": [35,[6]],
		"/admin/device-compatibility/create": [37,[6]],
		"/admin/device-compatibility/[id]": [36,[6]],
		"/admin/firmwares": [38,[6]],
		"/admin/linked-devices": [39,[6]],
		"/admin/logs": [40,[6]],
		"/admin/orders": [41,[6]],
		"/admin/orders/export-vat": [42,[6]],
		"/admin/products": [43,[6]],
		"/admin/products/create": [45,[6]],
		"/admin/products/[id]": [44,[6]],
		"/admin/users": [46,[6]],
		"/admin/users/[id]": [47,[6]],
		"/(app)/available-hacks": [9,[2]],
		"/(app)/login": [10,[2]],
		"/(app)/register": [11,[2]],
		"/(app)/reset-password": [12,[2]],
		"/(app)/reset-password/[id]": [13,[2]],
		"/(app)/two-factor": [14,[2]],
		"/(app)/user": [15,[2,3]],
		"/(app)/user/orders": [16,[2,3]],
		"/(app)/user/reseller": [17,[2,3,4]],
		"/(app)/user/reseller/orders/create": [18,[2,3,4]],
		"/(app)/user/settings/computers": [19,[2,3,5]],
		"/(app)/user/settings/computers/[id]": [20,[2,3,5]],
		"/(app)/user/settings/logs": [21,[2,3,5]],
		"/(app)/user/settings/password": [22,[2,3,5]],
		"/(app)/user/settings/profile": [23,[2,3,5]],
		"/(app)/user/settings/registered-licenses": [24,[2,3,5]],
		"/(app)/user/settings/two-factor": [25,[2,3,5]],
		"/(app)/user/settings/unclaimed-licenses": [26,[2,3,5]],
		"/(app)/verify-email": [27,[2]],
		"/(app)/[...path]": [~8,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';